import React, { Fragment, useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { fireVehicleShowcaseThumbnailSelect } from 'client/engagement-handlers/home-engagement-handler/home-engagement-handler';
import { CmsEntities } from 'client/data/models/cms';
import { DEFAULT_CONTENT } from 'client/data/cms/content';

import { useOnScreen } from 'site-modules/shared/hooks/use-on-screen';
import { RenderWhenViewable } from 'site-modules/shared/components/render-when-viewable/render-when-viewable';
import { AdUnit } from 'site-modules/shared/components/ad-unit/ad-unit';

import { CREATIVE_ID } from './constants';
import { VehicleShowcaseViewMobile } from './vehicle-showcase-view-mobile';
import { VehicleShowcaseSlide } from './vehicle-showcase-slide';
import { VehicleShowcaseControls } from './vehicle-showcase-controls';
import { VehicleShowcaseControlsMobile } from './vehicle-showcase-controls-mobile';

import './vehicle-showcase.scss';

function VehicleShowcaseUI({
  content,
  isMobile,
  bottomBorder,
  borderHiddenMdDown,
  borderHiddenLgUp,
  isLazyImages,
  adName,
  adCallsNumber,
  isMediumLayout,
  useContainer,
}) {
  const [selectedSlideIndex, setSelectedSlideIndex] = useState(0);
  const ref = useRef();
  const [isOnScreen] = useOnScreen(ref, {
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
  });

  const slides = (content && content.children()) || [];

  const fireTracking = useCallback(
    (newSlideIndex, currentSlideIndex, actionCause, skipTracking = false) => {
      if (newSlideIndex === currentSlideIndex) {
        return;
      }
      if (!skipTracking) {
        const trackingValue = slides[newSlideIndex].metadata('thumbTitle').value();
        fireVehicleShowcaseThumbnailSelect({
          actionCause,
          carouselIndex: newSlideIndex,
          carouselPrevIndex: currentSlideIndex,
          trackingValue,
          creativeId: CREATIVE_ID,
        });
      }
      setSelectedSlideIndex(newSlideIndex);
    },
    [slides]
  );

  const updateSelectedSlideMobile = useCallback(
    (currentSlideIndex, newSlideIndex) => {
      fireTracking(newSlideIndex, currentSlideIndex, 'swipe');
    },
    [fireTracking]
  );

  const updateSelectedSlide = useCallback(
    ({ currentTarget: { value, dataset } }) => {
      const newSlideIndex = parseInt(value, 10);
      const skipTracking = dataset && dataset.skipTracking === 'true';
      fireTracking(newSlideIndex, selectedSlideIndex, 'click', skipTracking);
    },
    [fireTracking, selectedSlideIndex]
  );

  if (isEmpty(slides)) {
    return null;
  }

  const cssProps = {};
  cssProps['--slide-length'] = slides.length;

  const adSlides = adCallsNumber ? new Array(adCallsNumber).fill() : slides;

  const controlsProps = {
    slides,
    updateSelectedSlide,
    selectedSlideIndex,
  };

  const slideControls = isMobile ? (
    <VehicleShowcaseControlsMobile {...controlsProps} />
  ) : (
    <VehicleShowcaseControls
      {...controlsProps}
      isLazyImages={isLazyImages}
      isMediumLayout={isMediumLayout}
      useContainer={useContainer}
    />
  );

  const sponsoredContentLabel = (
    <div className="medium font-weight-bold text-cool-gray-40 mx-0_5 mb-0_75 hidden-md-up">
      {slides[selectedSlideIndex].metadata('sponsoredContentLabel').value()}&nbsp;
    </div>
  );

  const placeholder = (
    <Fragment>
      {isMobile && (
        <Fragment>
          {sponsoredContentLabel}
          {slideControls}
        </Fragment>
      )}
      <VehicleShowcaseSlide
        slideContent={slides[0].getAllMetadata()}
        slideContentChildren={slides[0].children().map(item => item.getAllMetadata())}
        isMobile={isMobile}
        isActive
        slideId={slides[0].id}
        isLazyImages={isLazyImages}
        isMediumLayout={isMediumLayout}
        useContainer={useContainer}
        videoAutoPlay={false}
      />
      {!isMobile && slideControls}
    </Fragment>
  );

  const renderedSlides = slides.map((slideItem, index) => (
    <VehicleShowcaseSlide
      key={slideItem.id}
      slideContent={slideItem.getAllMetadata()}
      slideContentChildren={slideItem.children().map(item => item.getAllMetadata())}
      isMobile={isMobile}
      isActive={selectedSlideIndex === index}
      slideIndex={index}
      slideId={slideItem.id}
      isLazyImages={isLazyImages}
      isMediumLayout={isMediumLayout}
      useContainer={useContainer}
    />
  ));

  return (
    <Fragment>
      <div
        className={classnames('vehicle-showcase pos-r overflow-hidden', {
          'is-intersecting': isOnScreen,
        })}
        style={cssProps}
        data-tracking-parent={CREATIVE_ID}
        ref={ref}
      >
        <RenderWhenViewable verticalOffset="50%" placeholder={placeholder}>
          {isMobile && (
            <Fragment>
              {sponsoredContentLabel}
              {slideControls}
            </Fragment>
          )}

          {isMobile ? (
            <VehicleShowcaseViewMobile
              updateSelectedSlideMobile={updateSelectedSlideMobile}
              selectedSlideIndex={selectedSlideIndex}
            >
              {renderedSlides}
            </VehicleShowcaseViewMobile>
          ) : (
            <div className={classnames('pos-r d-flex w-all-slides')}>{renderedSlides}</div>
          )}

          {!isMobile && slideControls}
        </RenderWhenViewable>
        {adSlides.map((slide, index) => {
          const adProps = {
            key: (slide && slide.id) || `key-${index}`,
            position: `${index + 1}`,
            oneByOne: true,
            renderWhenViewable: false,
            ...(isMobile
              ? {
                  adName: `m${adName}`,
                  xs: true,
                  sm: true,
                }
              : {
                  adName,
                  md: true,
                  lg: true,
                  xl: true,
                }),
          };
          return <AdUnit {...adProps} />;
        })}
      </div>
      {bottomBorder && (
        <hr
          className={classnames('mt-1_5 mb-2', {
            'hidden-lg-up': borderHiddenLgUp,
            'hidden-md-down': borderHiddenMdDown,
          })}
        />
      )}
    </Fragment>
  );
}

VehicleShowcaseUI.propTypes = {
  content: CmsEntities.Content,
  isMobile: PropTypes.bool,
  bottomBorder: PropTypes.bool,
  borderHiddenLgUp: PropTypes.bool,
  borderHiddenMdDown: PropTypes.bool,
  isLazyImages: PropTypes.bool,
  adName: PropTypes.string,
  adCallsNumber: PropTypes.number,
  isMediumLayout: PropTypes.bool,
  useContainer: PropTypes.bool,
};

VehicleShowcaseUI.defaultProps = {
  content: DEFAULT_CONTENT,
  isMobile: false,
  bottomBorder: true,
  borderHiddenLgUp: false,
  borderHiddenMdDown: false,
  isLazyImages: false,
  adName: 'hpshow',
  adCallsNumber: 0,
  isMediumLayout: true,
  useContainer: false,
};

const mapStateToProps = state => ({
  isMobile: state.mobile,
});

export const VehicleShowcase = connect(mapStateToProps)(VehicleShowcaseUI);
