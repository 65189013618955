import React from 'react';
import PropTypes from 'prop-types';
import { compact } from 'lodash';
import { getImageUrl, SOURCES } from 'client/utils/image-helpers';
import { AspectRatioContainer } from 'site-modules/shared/components/aspect-ratio-container/aspect-ratio-container';
import { VehicleShowcaseVideo } from './vehicle-showcase-video';
import { VehicleShowcaseAnimation } from './vehicle-showcase-animation';

const parseAnimationImages = str => str.split('|').map(img => getImageUrl(img, null, SOURCES.STATIC));

export function VehicleShowcaseSlidePresentation({
  slide,
  headline,
  tabIndex,
  isActive,
  videoAutoPlay,
  isLazyImages,
  trackingPosition,
}) {
  const { video, animationImages, image, subTabName } = slide;

  return compact([
    !!video && (
      <VehicleShowcaseVideo
        video={video}
        isActive={isActive}
        videoAutoPlay={videoAutoPlay}
        trackingValue={subTabName}
        trackingPosition={trackingPosition}
        headline={headline}
      />
    ),
    !!animationImages && (
      <VehicleShowcaseAnimation
        hvsAnimationImagesArray={parseAnimationImages(animationImages)}
        hvsVehicle={headline}
        tabIndex={tabIndex}
        isLazyImages={isLazyImages}
      />
    ),
    <AspectRatioContainer aspectRatio="16:9" className="w-100">
      <img
        src={getImageUrl(image, null, SOURCES.STATIC)}
        alt=""
        loading={isLazyImages ? 'lazy' : undefined}
        className="rounded-8"
      />
    </AspectRatioContainer>,
  ])[0];
}

VehicleShowcaseSlidePresentation.propTypes = {
  slide: PropTypes.shape({
    video: PropTypes.string,
    animationImages: PropTypes.string,
    image: PropTypes.string,
    subTabName: PropTypes.string,
  }).isRequired,
  headline: PropTypes.string,
  tabIndex: PropTypes.number,
  isActive: PropTypes.bool,
  videoAutoPlay: PropTypes.bool,
  isLazyImages: PropTypes.bool,
  trackingPosition: PropTypes.string,
};

VehicleShowcaseSlidePresentation.defaultProps = {
  headline: '',
  tabIndex: 0,
  isActive: false,
  videoAutoPlay: false,
  isLazyImages: false,
  trackingPosition: '1',
};
